import { T } from '@deity-io/falcon-i18n';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCartContext } from '../../../context/CartContext';
import productionConfiguration from '../../../../config/default.json';
import readConfig from '../../../utils/readClientConfiguration';
import { removeFromCartEvent } from '../../../hooks/useAnalytics';
import formatLocalePrice, {currencySymbol} from '../../../utils/formatLocalePrice';
import calculateNcz30dPercentage from '../../../utils/calculateNcz30dPercentage';
import { useUserContext } from '../../../context/UserContext';
import './HeaderCartItem.scss';
import ProductPrice from "../../Product/ProductPrice/ProductPrice";
import PriceBlock from '../../Product/PriceBlock/PriceBlock';

const country = readConfig(productionConfiguration, 'countryCode');

const HeaderCartItem = ({ item }) => {
  const {
    quantity,
    id,
    product: {
      name,
      canonical_url,
      gallery,
      advanced_product_inventory,
      price_last_30,
      price: {
        regularPrice: {
          amount: { currency, value },
        },
      },
      prices: [{
        amount,
        old_amount,
        percentage,
        price_type
      }] = [{}],
    },
  } = item;

  const { isLoyaltyCardActive } = useUserContext();
  let enableQtyIncrements = '0';
  let qtyIncrements = 0;
  let unit = '';

  if (advanced_product_inventory) {
    enableQtyIncrements = advanced_product_inventory.enable_qty_increments;
    qtyIncrements = Number(advanced_product_inventory.qty_increments);
    unit = advanced_product_inventory.unit;
    if (advanced_product_inventory.unit && advanced_product_inventory.unit.charAt(0) !== '/') {
      unit ='/' + advanced_product_inventory.unit;
    }
  }

  const [input, setInput] = useState(quantity);
  const { updateCartItem } = useCartContext();

  const firstImage = gallery.find(item => item.position === 1);
  const anyImage = gallery.find(item => item);

  const formattedOldAmount = formatLocalePrice(old_amount);

  const isLoyalty = isLoyaltyCardActive && (item && item.product && item.product.prices.some(x => x.price_type === "simple_loyalty"));
  const calculatedPercentage = !isLoyalty && price_type && calculateNcz30dPercentage(amount, price_last_30);
  const { old_amount: oldLoyaltyAmount, percentage: loyaltyPercentage } =
  (isLoyalty && item.product.prices.find(x => x.price_type === "simple_loyalty")) || {};
  let chooseOldPrice;
  if(calculatedPercentage){
    chooseOldPrice = price_last_30;
  } else if (isLoyalty) {
    chooseOldPrice = oldLoyaltyAmount;
  } else {
    chooseOldPrice = null;
  }

  useEffect(() => {
    setInput(quantity);
  }, [quantity]);

  const onChange = e => {
    if (e.target.value.trim() === '') {
      setInput('');
      return;
    }
    const value = +e.target.value;
    if (isFinite(value) && value > 0) {
      setInput(value);
    }
  };

  const basePrice = item.product.prices.find(x => x.price_type === 'simple_regular');
  const basePriceAmount = parseFloat(basePrice.amount);
  const currentPrice = {
    amount: item.prices.priceIncludingTax.value,
    currency_symbol: currencySymbol(item.prices.priceIncludingTax.currency),
  };
  const percentageDiscount = 100 - Math.round(currentPrice.amount * 100 / basePriceAmount, 0);
  const hasPromoPrice = percentageDiscount > 0;


  return (
    <div className="cart-item-wrapper">
      <div className="cart-item">
        <Link to={"/" + canonical_url}>
          {firstImage && firstImage.url && (
            <img src={firstImage.url} alt={firstImage.label} className="cart-item__img" />
          )}
          {!firstImage && anyImage && anyImage.url && (
            <img src={anyImage.url} alt={anyImage.label} className="cart-item__img" />
          )}
        </Link>
        <div className="cart-item__info">
          <div className="cart-item__name">
            <Link to={"/" + canonical_url}>{name}</Link>
          </div>
          <div className="cart-item__prices">
            <div className={hasPromoPrice ? 'hasPromoPrice' : null}>
              <PriceBlock product={item.product} hideLast30={true} hideLabels={true}/>
            </div>
          </div>
          <div className="cart-item__quantity">
            <label htmlFor="quantity" className="quantity__label">
              <T id="headerCart.quantity" />
            </label>{' '}
            {enableQtyIncrements === '0' && (
              <input
                className="cart-item__quantity-input"
                id="quantity"
                type="number"
                value={input}
                onChange={onChange}
              />
            )}
            {enableQtyIncrements === '1' && (
              <p className="cart-item__quantity-packageText"> {(input / qtyIncrements).toFixed()} <T id="product.package" />(a)</p>
            )}
            {input !== '' && input !== quantity ? (
              <button className="cart-item__quantity-confirm" onClick={() => updateCartItem(id, input)}>
                <T id="headerCart.confirm" />
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="cart-item-actions">
        <Link to={"/" + canonical_url} className="cart-item__to-product" />
        <div
          onClick={() => {
            updateCartItem(id, 0);
            removeFromCartEvent(item.product, quantity);
          }}
          className="cart-item__remove"
        />
      </div>
    </div>
  );
};

export default HeaderCartItem;
